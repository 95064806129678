export default {
    title: "light",

    fonts: {
        primary: "Fredoka One",
        secondary: "Varela Round",
    },

    colors: {
        primary: "#5DA55B",
        secondary: "#4B4B4B",
        tertiary: "#323232",
        quaternary: "#DC863D",

        background: "#f5f5f5",
        text: "#333",
        subtext: "#f2f2f2",

        white: "#fff",
        black: "#000",
        gray: "#7D7B7B",

        hoverPrimary: "#4a8448",
        hoverSecondary: "#d1159c",
        hoverTertiary: "#d1159c",
        hoverQuaternary: "#ac6120",
    },
};

import React from "react";
import { BsTelephoneFill } from "react-icons/bs";
import { HiLocationMarker, HiLockClosed } from "react-icons/hi";
import { HashLink as Link } from "react-router-hash-link";

import { RouteURLs } from "../../Routes/RouteData";
import { DefaultConfigs } from "../../controller/DefaultConfigs/indes";

import Logo from "../../assets/logo.png";

import { Container } from "./styles";

const Header = () => (
    <Container>
        <div className="cardtop">
            <div className="itenscardtop">
                <div className="ballsicon">
                    <HiLocationMarker size={16} />
                </div>
                <h6 className="textscardtop">{DefaultConfigs.openingHours}</h6>
            </div>
            <div className="itenscardtop">
                <div className="ballsicon">
                    <BsTelephoneFill size={16} />
                </div>
                <h6 className="textscardtop">
                    {DefaultConfigs.contactNumberMask}
                </h6>
            </div>
        </div>
        <nav className="navbar navbar-expand-lg ">
            <div className="container-fluid">
                <Link className="navbar-brand" to={RouteURLs.home}>
                    <img className="img-fluid logo" src={Logo} alt="logo" />
                </Link>
                <button
                    className="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >
                    <span className="navbar-toggler-icon" />
                </button>
                <div
                    className="collapse navbar-collapse"
                    id="navbarSupportedContent"
                >
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0" />
                    <ul className="navbar-nav menu">
                        <li className="nav-item">
                            <Link
                                className="nav-link menuItem"
                                aria-current="page"
                                to={`${RouteURLs.home}#inicio`}
                            >
                                Início
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link
                                className="nav-link menuItem"
                                to={`${RouteURLs.home}#sobrenos`}
                            >
                                Sobre nós
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link
                                className="nav-link menuItem"
                                to={`${RouteURLs.home}#servicos`}
                            >
                                Serviços
                            </Link>
                        </li>
                        <li className="nav-item contact">
                            <Link
                                className="nav-link menuItem"
                                to={`${RouteURLs.home}#contatos`}
                            >
                                Contatos
                            </Link>
                        </li>
                        <li className="nav-item">
                            <a
                                className=" nav-link rastreamento"
                                href={DefaultConfigs.redirectTrackingLink}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Rastreamento
                            </a>
                        </li>
                        <li className="nav-item">
                            <Link
                                className=" nav-link buttonadmin"
                                to={RouteURLs.login}
                            >
                                <HiLockClosed size={20} />
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    </Container>
);

export default Header;

import styled from "styled-components";

export const Container = styled.div`
    @media (min-width: 576px) {
        max-width: 540px;
        margin: 0 auto;
        text-align: center;
    }

    @media (min-width: 768px) {
        max-width: 720px;
        margin: 0 auto;
        text-align: center;
    }

    @media (min-width: 992px) {
        max-width: 960px;
        margin: 0 auto;
        text-align: center;
    }

    @media (min-width: 1200px) {
        max-width: 1600px;
        margin: 0 auto;
        text-align: center;
    }

    .cardtop {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex-direction: row;

        padding: 15px 0;

        background-color: ${({ theme }) => theme.colors.tertiary};
    }

    .ballsicon {
        width: 30px;
        height: 30px;

        display: flex;
        justify-content: center;
        align-items: center;

        background-color: ${({ theme }) => theme.colors.primary};
        color: ${({ theme }) => theme.colors.white};

        border-radius: 15px;

        margin-right: 5px;
    }

    .textscardtop {
        padding-top: 2%;
        color: white;
    }

    .itenscardtop {
        display: flex;
        justify-content: center;
        align-items: center;

        margin-right: 30px;
    }

    .rastreamento {
        width: 178px;
        height: 45px;

        display: flex;
        justify-content: center;
        align-items: center;

        margin-left: 15px;

        font-size: 1.2rem;

        border-radius: 60px;

        background-color: ${({ theme }) => theme.colors.primary};
        color: ${({ theme }) => theme.colors.white};
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }

    .rastreamento:hover {
        background-color: ${({ theme }) => theme.colors.hoverPrimary};
    }

    .alignitensadm {
        padding-left: 50%;
    }

    .buttonadmin {
        width: 45px;
        height: 45px;

        display: flex;
        justify-content: center;
        align-items: center;

        margin-left: 11px;

        font-size: 1.2rem;

        border-radius: 45px;

        background-color: ${({ theme }) => theme.colors.quaternary};
        color: ${({ theme }) => theme.colors.white};
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }

    .buttonadmin:hover {
        background-color: ${({ theme }) => theme.colors.hoverQuaternary};
    }

    .menu {
        padding: 1%;
    }

    @media only screen and (max-width: 600px) {
        .menu {
            text-align: center;
            margin: 0 auto;
        }
        .buttonadmin {
            margin-left: 44%;
        }
        .rastreamento {
            margin-top: 3%;
            margin-left: 27%;
            margin-bottom: 2%;
        }
    }

    .menuItem {
        font-size: 1.3rem;
    }

    .menuItem:hover {
        border-bottom: 2px solid #5da55b;
    }

    .logo {
        width: 130px;
        height: 113px;
        margin-left: 60%;
    }

    @media only screen and (max-width: 600px) {
        .logo {
            width: 80px;
            height: 70px;
            margin-left: 0;
        }
    }
`;

export const DefaultConfigs = {
    contactNumber: "5585999999999",
    contactNumberMask: "(85) 9999-99999",
    contactEmail: "email@email.com",
    address: "R. Eliane Monteiro Barros, 39 - Icaraí, Caucaia - CE, 61624-540",
    openingHours: "Segunda a Sexta 08:00 às 17:00",
    redirectTrackingLink: "http://s1.sdsat.com.br/sdsat/scripts/login.php",
    messageDefaultEmail: {
        subject: "?subject=Contato SDGestão",
        body: "&body= Olá, gostaria de mais informações!",
    },
    messageDefaultWhatsapp: "?text=Olá, gostaria de mais informações!".replace(
        " ",
        "%20"
    ),
    dataSergio: {
        whatsappNumber: "",
        email: "",
    },
    dataVictor: {
        whatsappNumber: "",
        email: "",
        intagram: "",
    },
};
